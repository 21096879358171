module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

let advancedCounter = 2;

function createInput(row) {
    switch(row.type) {
        case 'text':

__p+='\n            <input type="text" id="'+
((__t=( row.id  ))==null?'':__t)+
'" value="'+
((__t=( _.escape(row.value || '')  ))==null?'':__t)+
'"\n                placeholder="'+
((__t=( row.placeholder || ''  ))==null?'':__t)+
'" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'/>\n';

        break;
    case 'blob':

__p+='\n        <textarea id="'+
((__t=( row.id  ))==null?'':__t)+
'" placeholder="'+
((__t=( row.placeholder||''  ))==null?'':__t)+
'"\n                '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>'+
((__t=( row.value||''  ))==null?'':__t)+
'</textarea>\n';

    break;
    case 'spacer':

__p+='\n    <div class="modal-spacer" id="'+
((__t=( row.id  ))==null?'':__t)+
'"></div>\n';

    break;
    case 'password':

__p+='\n    <input type="password" id="'+
((__t=( row.id  ))==null?'':__t)+
'" value="'+
((__t=( row.value || ''  ))==null?'':__t)+
'" placeholder="'+
((__t=( row.placeholder || ''  ))==null?'':__t)+
'"\n            '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'/>\n';

    break;
    case 'readonly':

__p+='\n    <div class="modal-text" id="'+
((__t=( row.id  ))==null?'':__t)+
'" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>'+
((__t=( row.value || ''  ))==null?'':__t)+
'</div>\n';

    break;
    case 'checkbox':
        var checked = '',
                disabled = '';
        if (row.checked) {
            checked = 'checked';
        }
        if (row.disabled) {
            disabled = 'disabled';
        }

__p+='\n    <input type="checkbox" id="'+
((__t=( row.id  ))==null?'':__t)+
'" value="'+
((__t=( row.value  ))==null?'':__t)+
'" '+
((__t=( checked  ))==null?'':__t)+
' '+
((__t=( disabled  ))==null?'':__t)+
'\n            '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'/>\n';

    break;
    case 'select':
      let selectDisabled = '';
      let selectTitle = '';
      if (row.options && row.options.length === 1) {
        selectDisabled = ' disabled';
        selectTitle = 'Only one entry available.'
      }

__p+='\n        <select id="'+
((__t=( row.id  ))==null?'':__t)+
'" class="modalSelect" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
' '+
((__t=( selectTitle ? 'title="' + selectTitle + '"' : '' ))==null?'':__t)+
' '+
((__t=(selectDisabled))==null?'':__t)+
'>\n        ';
 _.each(row.options, function(opt) { 
__p+='\n            <option value="'+
((__t=( opt.value  ))==null?'':__t)+
'" '+
((__t=( row.selected === opt.value?'selected':''  ))==null?'':__t)+
'\n                    '+
((__t=( opt.style ? 'style="' + opt.style + '"' : '' ))==null?'':__t)+
'>\n                '+
((__t=( opt.label  ))==null?'':__t)+
'\n            </option>\n        ';
 })  
__p+='\n        </select>\n';

    break;
    case 'select2':

__p+='\n    <input type="hidden" id="'+
((__t=( row.id  ))==null?'':__t)+
'" value="'+
((__t=( row.value || ''  ))==null?'':__t)+
'" placeholder="'+
((__t=( row.placeholder || ''  ))==null?'':__t)+
'"/>\n    ';
 if (row.addDelete) { 
__p+='\n        <button class="graphViewer-icon-button gv-icon-small add"></button>\n    ';
 } 
__p+='\n    ';
 if (row.addDelete) { 
__p+='\n        <button class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete"></button>\n    ';
 } 
__p+='\n';

    break;
    case 'jsoneditor':

__p+='\n    <div id="jsoneditor" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'></div>\n';

    break
    case 'table':

__p+='\n    <table class="pure-table pure-table-bordered" id="'+
((__t=( row.id  ))==null?'':__t)+
'" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>\n        ';
 if (Array.isArray(row.head) && row.head.length) { 
__p+='\n            <thead>\n            <tr>\n                ';
 row.head.forEach(header => { 
__p+='\n\n                    <th style="height: unset;">'+
((__t=( header ))==null?'':__t)+
'</th>\n                ';
 }); 
__p+='\n                <th style="height: unset; min-width: 30px;">\n                    <span style="display: inline-flex">\n                                <button class="graphViewer-icon-button gv-icon-small add addAfter"></button>\n                    </span>\n                </th>\n            </tr>\n            </thead>\n        ';
 } 
__p+='\n        <tbody>\n        ';
 row.rows.forEach((contentRow, idx) => {
            const contentRowId = `${row.id}-row-${idx}`;
        
__p+='\n            <tr id="'+
((__t=( contentRowId ))==null?'':__t)+
'">\n            ';
 contentRow.forEach(cell => {
                cell.style = [(cell.style || ''), "width: unset;", "margin: 0;"].join(' ');
            
__p+='\n                <td>\n                    ';
 createInput(cell) 
__p+='\n                </td>\n                ';
 }); 
__p+='\n                <td>\n                            <span style="display: inline-flex">\n                                ';
 if (idx) {
__p+='\n                                <button style="margin-left: 5px;"\n                                        class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete addDelete">\n                                </button>\n                                ';
 } 
__p+='\n                            </span>\n                </td>\n            </tr>\n        ';
 }); 
__p+='\n        </tbody>\n    </table>\n';

    break;
    }
}

function generateAdvancedSection(header, content) { 
__p+='\n<div class="accordion" id="accordion'+
((__t=(advancedCounter))==null?'':__t)+
'">\n  <div class="accordion-group">\n    <div class="accordion-heading">\n      <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion'+
((__t=(advancedCounter))==null?'':__t)+
'" href="#collapseOne'+
((__t=(advancedCounter))==null?'':__t)+
'">\n        <span>'+
((__t=( header ))==null?'':__t)+
'</span><span><b class="caret"></b></span>\n      </a>\n      </div>\n      <div id="collapseOne'+
((__t=(advancedCounter))==null?'':__t)+
'" class="accordion-body collapse out">\n        <div class="accordion-inner" style="margin-right: 15px;">\n          <table>\n            <tbody>\n            ';

            _.each(content, function (row) {
              createTR(row);
            });
            
__p+='\n          </tbody>\n        </table>\n      </div>\n    </div>\n  </div>\n</div>\n';
 advancedCounter++;}

var createTR = function(row) {
    var mandatory = '';
    if (row.mandatory) {
        mandatory = '*';
    }

__p+='\n\n<tr class="tableRow" id="'+
((__t=( 'row_' + row.id ))==null?'':__t)+
'">\n  ';
 if (typeof row.label === 'string') { 
__p+='\n    <th class="collectionTh">\n      ';
 if (row.removeColon) { 
__p+='\n        '+
((__t=( row.label))==null?'':__t)+
''+
((__t=( mandatory ))==null?'':__t)+
'\n      ';
 } else { 
__p+='\n        '+
((__t=( row.label))==null?'':__t)+
''+
((__t=( mandatory ))==null?'':__t)+
':\n      ';
 } 
__p+='\n    </th>\n  ';
 } 
__p+='\n\n    <th class="collectionTh"'+
((__t=( row.info ? '' : ' colspan="2"' ))==null?'':__t)+
'>\n        ';
 createInput(row) 
__p+='\n        ';
 if (row.info) { 
__p+='\n        </th>\n        <th>\n          <span class="modalTooltips arangoicon icon_arangodb_info" title="'+
((__t=(row.info))==null?'':__t)+
'">\n          </span>\n        ';
 } 
__p+='\n      </th>\n    </tr>\n    ';

  };
  
__p+='\n  ';
 if (content) { 
__p+='\n    <table>\n      <tbody>\n        ';

        _.each(content, function(row) {
          createTR(row);
        });
        
__p+='\n      </tbody>\n    </table>\n  ';
 } 
__p+='\n  ';
 if (info) { 
__p+='\n    '+
((__t=( info ))==null?'':__t)+
'\n  ';
 } 
__p+='\n  ';
 if (advancedContent && Array.isArray(advancedContent)) {
    _.forEach(advancedContent, function (aC) {
      generateAdvancedSection(aC.header, aC.content);
    });
  } else if (advancedContent) {
    generateAdvancedSection(advancedContent.header, advancedContent.content);
  } 
__p+='\n';
}
return __p;
};
